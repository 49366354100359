.c-doc-select{
    &__value{
        background: $bg-color2;
        padding: 3px 10px;
        border-radius: 4px;
        margin: 5px;
        display: flex;
        align-items: center;
        &-close{
            width: 16px;
            display: flex;
            align-items: center;
            justify-content: center;
            padding-left: 9px;
            padding-bottom: 1px;
            svg {
                width: 16px;
                height: auto;
            }
        }
    }
    &__option{
        display: flex;
        justify-content: space-between;
        padding-top: 12px;
        padding-bottom: 12px;
        cursor: pointer;
        border-bottom: solid 1px rgba($color1, .63);
        color: $color1;
        &-left{
            display: flex;
            flex-direction: column;
            justify-content: center;
        }
        &-name{
            display: block;
            font-size: 1rem;
            font-weight: bold;
            line-height: 0.63;
        }
        &-spcl{
            font-size: 0.75rem;
            line-height: 0.83;
        }
        &-icon{
            width: 35px;
            height: 35px;
            display: flex;
            border: solid 1px currentColor;
            align-items: center;
            justify-content: center;
            border-radius: 50%;
            &--selected{
                border: solid 1px #3674e8;
                background-color: #3674e8;
                svg path{
                    stroke: $white-color;
                }
            }
        }
        &--selected{
            color: #3674e8;
        }
    }
    &__control{
        min-height: 48px !important;
    }
    &__menu{
        &-list{
            padding-left: 10px;
            padding-right: 10px;
            @include scrollbar;
        }
    }
}
