.c-sidebar {
  @extend .sticky-top;
  align-self: flex-start;
  top: 20px;
 // height: calc(100vh - 103px);
}
// sidebar
ul.sidebar-item:first-child {
  max-height: calc(100vh - 295px);
  overflow: auto;
  min-height: 100px;
}
ul.sidebar-item:nth-child(3) {
  margin-bottom: 20px;
}
.sidebar-item:first-child::-webkit-scrollbar {
  width: 7px;
}

/* Track */
.sidebar-item:first-child::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey; 
  border-radius: 10px;
}
 
/* Handle */
.sidebar-item:first-child::-webkit-scrollbar-thumb {
  background: #a9a8a8; 
  border-radius: 10px;
}

/* Handle on hover */
.sidebar-item:first-child::-webkit-scrollbar-thumb:hover {
  background: #a9a8a8; 
}
.copyright {
  position: absolute;
  bottom: 0;
  display: flex;
  align-items: center;
  margin-bottom: 0;
}
.sidebar-item {
  padding: 0;
  margin: 0;

  li {
    font-size: 0.9375rem;
    list-style: none;

    @include onMaxWidth(1500px) {
      font-size: 0.8125rem;
    }

    @include onSmallScreen {
      font-size: 0.75rem;
    }

    a {
      padding: 1em;
      display: flex;
      align-items: center;
      text-decoration: none;

      img,
      i {
        height: 1.4em;
        width: 1.4em;
        margin-right: 0.5em;
      }
    }
  }

  button {
    font-size: 0.9375rem;
    list-style: none;
    margin-right: 0;
    width: 100%;
    padding-left: 0;
    border: 0;
    background-color: transparent;
    @include onMaxWidth(1500px) {
      font-size: 0.8125rem;
    }

    @include onSmallScreen {
      font-size: 0.75rem;
    }

    a {
      padding: 1em;
      display: flex;
      align-items: center;
      text-decoration: none;

      img,
      i {
        height: 1.4em;
        width: 1.4em;
        margin-right: 0.5em;
      }
      svg {
        fill: $color1;
      }
    }
  }

  span {
    font-family: $base-font2;
    line-height: 1.53;
    text-align: left;
    color: $color1;
  }

  .sidebar-active,
  li:hover {
    border-radius: 6px;
    background-color: $color2;

    @include onMobileScreen {
      width: 120px;
    }

    span {
      color: white;
    }

    .img-fluid {
      filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(0deg)
        brightness(106%) contrast(106%);
    }
  }
  .selcted-active {
    background-color: #e7e7f7;
    border-radius: 6px;
  }
  .activeDropDown,
  button:hover {
    border-radius: 6px;
    background-color: $color2;

    @include onMobileScreen {
      width: 120px;
    }

    span {
      color: white;
    }

    .img-fluid {
      filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(0deg)
        brightness(106%) contrast(106%);
    }
    svg {
      fill: #fff;
    }
  }
}

.copyright {
  &-image {
    width: 53.8px;
    height: 25.7px;
    position: absolute;
    left: 40px;
    top: 94%;

    &-sidebar {
      width: 40px;
      height: 20px;
      //position: fixed;
      left: 20px;
      top: 94%;
      margin-right: 5px;

      @include onOnlyThisWidth(1024px) {
        left: 10px;
      }
    }
  }

  &-text {
    position: absolute;
    left: 95px;
    top: 94.3%;
    font-family: $base-font2;
    font-size: 0.875rem;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: left;
    color: $color6;

    &-sidebar {
      //position: fixed;
      left: 60px;
      top: 94.1%;
      font-family: Poppins;
      font-size: 0.75rem;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.5;
      letter-spacing: normal;
      text-align: left;
      color: $color6;

      @include onOnlyThisWidth(1024px) {
        left: 50px;
      }

      @include onMobileScreen {
        width: 60px;
        overflow-wrap: break-word;
      }
    }
  }
}
