.screenSize {
  width: 100vw;
  height: 100vh;
}
.mt-50 {
  margin-top: -50px;
  margin-left: -25px;
}
.pos-relative {
  position: relative;
}
