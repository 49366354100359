.c-watch-video{
    &__dialog{
        max-width: 800px;
        width: calc(100% - 100px);
    }
    &__btn {
        position: absolute;
        top: 0;
        bottom: 55px;
        margin: auto;
        height: 60px;
        width: 30px;
        z-index: 9;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        background-color: $light-blue;
        &--prev{
            left: 0;
        }
        &--next{
            right: 0;
        }
    }
}