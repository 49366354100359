#myTab .tab-nav li {
  padding: 15px 15px;
  background: #f5f5f5;
  border-radius: 0;

  color: #387af6;
  border-radius: 3px 0 0 0 !important;
  cursor: pointer;
  min-width: 50%;
  font-size: 20px;

  border-bottom: 0;
  text-align: center;
}
#myTab li.active {
  background: #cbcbcb;
  color: black;
  border-bottom: 3px solid #387af6 !important;
  font-weight: bold;
}
