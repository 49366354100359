.c-tabs{
    &__nav {
        display: flex;
        background-color: $light-blue-2;
        &-item {
            padding: 10px 15px;
            cursor: pointer;
            &--active {
                position: relative;
                &::before{
                    content: '';
                    display: block;
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    right: 0;
                    height: 3px;
                    background-color: $color2;
                }
            }
            &-text {
                margin: auto;
            }
        }
    }
}