// Dashboard Billing Inf0
.dash-billing-info {
    margin-bottom: 43px;
    .bill-icon {
        display: flex;
        align-items: center;
        height: 50px;
        justify-content: center;
        margin-bottom: 20px;
        img{
            max-width: 100%;
            max-height: 100%;
            object-fit: contain;
        }
    }
    .billing-item {
        padding-top: 20px;
        padding-bottom: 20px;
        margin-bottom: 30px;
        margin-top: 40px;
        border-right: solid 1px $light-blue;
        @include onMediumScreen{
            margin-bottom: 10px;
            margin-top: 20px;
        }
    }
    .bill-title {
        font-family: $base-font1;
        font-size: 1.125rem;
        line-height: 1.4;
        text-align: center;
        color: $color1;
        min-height: 47px;
        display: flex;
        // border: 1px solid;
        align-items: center;
        justify-content: center;
        @include onSmallScreen{
            font-size: 0.875rem;
        }
    }
    .bill-text {
        font-family: $base-font1;
        font-size: 2.25rem;
        font-weight: bold;
        line-height: 1.53;
        color: $color1;
        margin-bottom: 0;
        @include onSmallScreen{
            font-size: 1.5rem;
        }
    }
}

// Billing Info END