.c-attachments{
    &__list{
        display: flex;
        flex-wrap: wrap;
    }
    &__upload-loader{
        height: 75px;
        margin-bottom: 12px;
        margin-right: 12px;
        width: 75px;
        .loader-small{
            margin-top: 20px;
        }
    }
    &__file{
        text-align: center;
        margin-bottom: 12px;
        margin-right: 12px;
        width: 75px;
        &--has-filename{
            .c-attachments{
                &__file{
                    &-inner{               
                        margin-bottom: 12px;
                    }
                }
            }    
        }
        &-inner{
            position: relative;
            width: 75px;
            height: 75px;
            border-radius: 10px;
            background-color: $color3;
            display: flex;
            justify-content: center;
            align-items: center;
        }
        &-name{
            font-size: 0.75rem;
            @extend .text-ellipsis;
        }
        svg{
            display: block;
            width: 25px;
            height: auto;
            fill: $light-blue-2;
            path{
                fill: $light-blue-2 !important;
            }
        }
        .btn{
            position: absolute;
            right: -2px;
            top: -6px;
            width: 24px;
            height: 24px;
            border-radius: 24px;
            padding: 0;
            z-index: 2;
            svg{
                display: block;
                width: 100%;
                height: auto;
            }
        }
        .attachment-img{
            width: 100%;
            height: 100%;
            max-width: 100%;
            max-height: 100%;
            position: absolute;
            border-radius: 10px;
            left: 0;
            right: 0;
            bottom: 0;
            top: 0;
            margin: auto;
            z-index: 0;
            object-fit: cover;
        }
    }
}