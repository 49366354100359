.call {
  text-align: center;
}

.player-container {
  padding: 20px;
  text-align: initial;
}

.button-group {
  padding: 20px;
}

.call-form {
  padding: 20px;
}

input,
button {
  margin-right: 10px;
}

.inline-block {
  display: inline-block;
  margin: 10px;
}

.stream-2 {
  width: 320px;
  height: 450px;
  background-color: black;
  border-radius: 16px;
  position: relative;
  display: inline-block;
  margin: 10px;
}

.stream-2-initial {
  width: 80%;
  height: 250px;
  margin: auto;
  border-radius: 100%;
  background-color: dimgray;
  position: absolute;
  left: 10%;
  top: 24%;
}

.stream-2-initial p {
  text-align: center;
  font-size: 100px;
  color: #fff;
  margin-top: 55px;
}
