.px-20 {
  padding-left: 20px;
  padding-right: 20px;
}
.h-100vh {
  min-height: 100vh;
}
.mw-300 {
  min-width: 300px;
}
.p-7-15 {
  padding: 7px 20px;
}

.p-20 {
  padding: 20px;
}
.mr-15 {
  margin-right: 15px;
}
.mr-25 {
  margin-right: 25px;
}
.m-5-auto {
  margin: 60px auto;
}

.myLoader,
.myLoader:after {
  border-radius: 50%;
  width: 10em;
  height: 10em;
}
.myLoader.sm,
.myLoader.sm:after {
  border-radius: 50%;
  width: 5em;
  height: 5em;
}
.myLoader {
  font-size: 5px;
  position: relative;
  text-indent: -9999em;
  border-top: 1.1em solid rgba(0, 0, 0, 0.8);
  border-right: 1.1em solid rgba(0, 0, 0, 0.8);
  border-bottom: 1.1em solid rgba(0, 0, 0, 0.8);
  border-left: 1.1em solid rgba(255, 255, 255, 0);
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: load8 1.1s infinite linear;
  animation: load8 1.1s infinite linear;
}
.myLoader.light {
  border-top: 1.1em solid rgba(255, 255, 255, 0.8);
  border-right: 1.1em solid rgba(255, 255, 255, 0.8);
  border-bottom: 1.1em solid rgba(255, 255, 255, 0.8);
  border-left: 1.1em solid rgba(255, 255, 255, 0);
}

.myLoader.light.sm {
  font-size: 4px;
  border-top: 0.8em solid rgba(255, 255, 255, 0.8);
  border-right: 0.8em solid rgba(255, 255, 255, 0.8);
  border-bottom: 0.8em solid rgba(255, 255, 255, 0.8);
  border-left: 0.8em solid rgba(255, 255, 255, 0);
}
.myLoader.dark.sm {
  font-size: 3px;
  border-top: 0.8em solid rgba(0, 0, 0, 0.8);
  border-right: 0.8em solid rgba(0, 0, 0, 0.8);
  border-bottom: 0.8em solid rgba(0, 0, 0, 0.8);
  border-left: 0.8em solid rgba(255, 255, 255, 0);
}
