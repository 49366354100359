@import './select';

.min-h-100{
    min-height: 100%;
}

.c-textarea{
    width: 100%;
    height: 216px;
    padding: 26px 26.8px 28.5px 28px;
    border-radius: 10px;
    background-color: $white-color;
    overflow: auto;
    resize: none;
    @include scrollbar;
    font-size: 1.125rem;
    font-weight: 300;
    line-height: 1.44;
    color: $color5;
    border: none;
    box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.16);
    &:focus{
        outline: none;
    }
}
.c-basic-heading{
    font-size: 1.25rem;
    font-weight: bold;
    line-height: 1.23;
    color: $color1;
    padding-top: 15px;
}

.text-ellipsis{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.c-error-block{
    background-color: rgba($danger, 0.08);
}