.therapyListWrapper {
  position: relative;
  select {
    // border: 0;
    // background: transparent;
  }
  .input-group-prepend {
    width: 44px;
    background-color: #387af6;
    border-top-right-radius: 12px;
    border-bottom-right-radius: 12px;
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
  }
}
