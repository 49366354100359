.EditIcon {
  cursor: pointer;
  color: var(--primaryColor);
}
.fieldError {
  color: red;
}
.form-group {
  .fieldError + .fieldError {
    display: none;
  }
}
