// dashbaord
.dashboard-count,
.dashboard-billing-count {
    background-image: linear-gradient(82deg, $color2 10%, #0033ff 89%);
    justify-content: space-between;
    padding: 20px;
    border-radius: 15px;
    margin-bottom: 30px;
    font-size: 1.25rem;

    @include onLargeScreen{
        font-size: 1.125rem;
    }
    @include onMediumScreen{
        font-size: 1rem;
    }
    @include onSmallScreen{
        font-size: 0.875rem;
    }
    @include onMobileScreen{
        font-size: 0.75rem;
    }
    &>.row {
        align-items: center;
        justify-content: space-between;
    }
    .item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        color: #fff;
        img {
            max-width: 3.5em;
            margin-right: .3em;
        }
        .content {
            .count {
                font-family: $base-font1;
                font-size: 2.5em;
                font-weight: bold;
                line-height: 0.84;
                text-align: left;
                color: $white-color;
            }
            .title {
                font-family: $base-font1;
                font-size: 1.2em;
                line-height: 1.75;
                text-align: left;
                color: $white-color;
            }
            .billing-count {
                font-family: $base-font1;
                font-size: 2.5rem;
                font-weight: bold;
                line-height: 1.05;
                @include onMobileScreen{
                    font-size: 1.875rem;
                }
            }
            .payment-submit {
                color: #fbe200;
            }
            .payment-done {
                color: #abff00;
            }
            .payment-pending {
                color: #72ffec;
            }
            .billing-title {
                font-family: $base-font1;
                font-size: 1rem;
                line-height: 1.21;
                color: $white-color;
                @include onMobileScreen{
                    font-size: 0.75rem;
                }
            }
        }
    }
}