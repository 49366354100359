
/**
 START :: Therapy Session by Theripist CSS
*/

.therapy-session {
    display: flex;
    align-items: center;
    border-bottom: 1px solid $light-blue;
    table td {
        border: none;
    }
    .ts-time-session , .ts-session-duration{
        display: flex !important;
    }
    
    .ts-therapy-name {
        padding: 5px 10px 3px 10px;
        background-color: $color4;
        border-radius: 0px 4px 4px 0px;
        font-family: $base-font1;
        font-size: 1rem;
        // line-height: 0.63;
        color: $color1;
        text-align: center;
        display: block;
        @include onLargeScreen{
            font-size: 0.8125rem;
        }
    }
    .ts-family-name {
        font-family: $base-font1;
        font-size: 0.875rem;
        font-weight: 400;
        display: block;
        text-align: center;
        color: $color1;
        @include onLargeScreen{
            font-size: 0.75rem;
        }
    }
    .ts-interpreter {
        font-family: $base-font1;
        font-size: 0.875rem;
        // line-height: 0.86;
        text-align: left;
        color: $color1;
        display: flex;
        align-items: center;
        img{
            width: 1.2em;
            height: 1.2em;
            object-fit: contain;
        }
        @include onLargeScreen{
            font-size: 0.75rem;
        }
    }
    .dropdown-menu {
        .dropdown-item {
            font-family: $base-font1;
            font-size: 1.0625rem;
            line-height: 2.41;
            color: #3674e8;
            height: 20px;
            margin: 0px 0 0 0px;
            padding: 15px;
            text-align: center;
            align-items: center;
            display: flex;
            background: none;
            height: 30px;
        }
    }
    .dropleft {
       // z-index: 999;
        .dropdown-toggle {
            border: none;
            background: none;
        }
        .button {
            border: none;
        }
        .dropdown-toggle::before,
        .dropdown-toggle:active,
        .dropdown-toggle:focus {
            border: none;
        }
    }

    .ts-session{
        &-duration{
            font-size: 0.875rem;
            display: flex;
            align-items: center;
            @include setInnerImg;
        }
    }
}

.ts-soap-note-icon{
    svg{
        width: 30px;
    }
}
.ts-time{
    font-size: 0.875rem;
    color: $color1;
    @include onLargeScreen{
        font-size: 0.75rem;
    }
}
.ts-time-interval {
    padding: 5px 10px 3px 10px;
    background-color: $color4;
    border-radius: 0px 4px 4px 0px;
    font-family: $base-font1;
    font-size: 0.875rem;
    font-weight: 300;
    line-height: 1.36;
    color: $color1;
    display: flex;
    align-items: center;
    @include setInnerImg;
    @include onLargeScreen{
        font-size: 0.75rem;
    }
}
/**
 END :: Therapy Session by Theripist CSS
*/


// @include onMinWidth(1800px){
//     .therapy-session table td{
//         &:nth-child(1){
//             width: 220px;
//         }
//         &:nth-child(2){
//             width: 300px;
//         }
            
//         &:nth-child(3){
//             width: 140px;
//         }
//         &:nth-child(4){
//             width: 200px;
//         }
//         &:nth-child(5){
//             width: 180px;
//         }
//         &:nth-child(6){
//             width: 200px;
//         }
//         &:nth-child(7){
//             width: 150px;
//             text-align: center;
//         }
//     }
// }

.c-therapy-session-table{

    .btn{
        font-size: 0.9375rem;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        img{
            width: 1.2em;
            height: 1.2em;
            object-fit: contain;
        }
        @include onLargeScreen{
            font-size: 0.75rem;
        }
    }
    .billing-item {
        margin-top: auto;
        margin-bottom: auto;
    }
    .date-width{
        .session-dd{
            line-height: 1.2;
            text-align: right;
            font-size: 2.5rem;
            @include onLargeScreen{
                font-size: 2.2rem;
                
            }
            @include onMediumScreen{
                font-size: 2rem;
            }
        }
        small{
            font-size: .32em;
            line-height: 1;
            display: block;
        }
    }
    .session-status{
        font-size: 0.9rem;
        img, svg{
            width: 1em;
            height: 1em;
        }
        min-height: 29px;
        @include onLargeScreen{
            min-height: 26px;
            font-size: 0.8rem;
        }
    }
    .dropdown-toggle{
        @include onMediumScreen{
            img{
                height: 1rem;
            }
        }
    }
}