.c-error-page{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    min-height: 100%;
    &__imagee{
        max-width: 200px;
        max-height: 150px;
        height: auto;
        width: auto;
        object-fit: contain;
    }
}