@mixin onFullscreen {
    @at-root{
        .fullscreen-enabled & {
            @content;
        }
    }
}
@mixin onMinWidth($width) {
    @media screen and (min-width: $width) {
        @content;
    }
}
@mixin onMaxWidth($width) {
    @media screen and (max-width: $width) {
        @content;
    }
}

@mixin onOnlyThisWidth($width) {
    @media screen and (width: $width) {
        @content;
    }
}

@mixin onLargeScreen {
    @include onMaxWidth(1600px) {
        @content;
    }
}

@mixin onMediumScreen {
    @include onMaxWidth(1400px) {
        @content;
    }
}

@mixin onSmallScreen {
    @include onMaxWidth(1294px) {
        @content;
    }
}

@mixin onlyOnTabScreen {
    @media screen and (min-width:768px) and (max-width:1024px){
        @content;
    }
}

@mixin onMobileScreen {
    @include onMaxWidth(768px) {
        @content;
    }
}

@mixin scrollbar {
    &::-webkit-scrollbar {
        width: 5px;
        opacity: 0;
    }
    /* Track */
    &::-webkit-scrollbar-track {
        background: #f1f1f1; 
        opacity: 0;
    }
    /* Handle */
    &::-webkit-scrollbar-thumb {
        background: #888;
        opacity: 0; 
    }
    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
        background: #555;
        opacity: 0; 
    }
}

//----------

// inputs 
@mixin setSelect {
    border-radius: 12px;
    border: solid 2px $light-blue;
    background-color: $body-bg;
    height: 65px;
    font-family: $base-font1;
    font-size: 1.125rem;
    line-height: 1.33;
    text-align: left;
    color: $color5;
    background-image: url('../assets/down-arrow-white.svg'), linear-gradient($color2, $color2);
    background-position: center right 15px, center right;
    background-size: 16px 10px, 50px 200px;
    background-repeat: no-repeat;
}

// heading
@mixin setMainHeading{
    font-family: $base-font1;
    font-size: 1.75rem;
    font-weight: 500;
    line-height: 1.5;
    color: $color1;
    @include onLargeScreen{
        font-size: 1.5rem;
    }
    @include onMediumScreen{
        font-size: 1.3rem;
    }
    @include onSmallScreen{
        font-size: 1.25rem;
    }
}

@mixin setHeading {
    font-family: $base-font1;
    font-size: 1.25rem;
    font-weight: 500;
    line-height: 1.3;
    text-align: left;
    color: $color1;
    @include onMediumScreen{
        font-size: 1.1rem;
    }
    @include onSmallScreen{
        font-size: 1rem;
    }
}


// image
@mixin setInnerImg($size: 1.2em) {
    img{
        width: $size;
        height: $size;
        object-fit: contain;
    }
}

@mixin setThumbinail($size: 52px) {
    width: $size;
    height: $size;
    object-fit: cover;
    border-radius: 100%;
    @include onLargeScreen{
        width: $size * .8;
        height: $size * .8;
    }
    @include onLargeScreen{
        width: $size * .6;
        height: $size * .6;
    }
    @include onMobileScreen{
        width: $size * .45;
        height: $size * .45;
    } 
}
