#root{
    display: flex;
    flex-direction: column;
}
.c-recent-sessions{
    @include onMediumScreen{
        font-size: 0.875rem;
    }
    .image{
        @include setThumbinail;
    }
}
.modal-title{
    @include setMainHeading;
}