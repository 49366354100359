.c-query{
    &__main{
        background-color: $bg-color2;
        padding: 40px;
        border-radius: 8px;
        @include onMediumScreen{
            padding: 20px;
            border-radius: 5px;
        }
    }
    &__item{
        padding-bottom: 15px;
        padding-top: 15px;
        position: relative;
        &:after{
            z-index: 1;
            content: "";
            display: block;
            position: absolute;
            width: 1px;
            top: 19px;
            background: #9cbefe;
            left: 19px;
            bottom: -20px;
        }
        &:last-child:after{
            display: none;
        }
        &--translation{
            margin-top: 10px;
            background-color: $bg-color3;
            padding: 20px;
            border-radius: 8px;
            &:after{
                display: none;
            }
            @include onMediumScreen{
                padding: 12px;
                border-radius: 5px;
            }
        }
    }
    &__info{
        position: relative;
        z-index: 3;
        display: flex;
        justify-content: space-between;
        &-date{
            font-size: 1.125rem;
            font-weight: 300;
            color: $color1;
        }
        &-user{
            display: flex;
            align-items: center;
            &-name{
                text-transform: capitalize;
                color: $color1;
                font-size: 1.25rem;
            }
            &-img{
                width: 38px;
                height: 38px;
                margin-right: 10px;
                border-radius: 100%;
                display: block;
                overflow: hidden;
                background-color: rgba($black, .2);
            }
        }
    }
    &__content{
        padding-left: 48px;
        &-text{
            font-size: 1.125rem;
            font-weight: 300;
            line-height: 1.44;
            color: $color1;
            margin-bottom: 0;
        }
        &-heading{
            &::first-letter{
                text-transform: uppercase;
            }
            font-size: 1.25rem;
            font-weight: 500;
            line-height: 1.2;
            color: $color1;
            margin-bottom: 0;
        }
    }
}