.c-session-card {
    --session-gutter: 15px;
    $sessingGutter: var(--session-gutter);
    $sessingGutterNeg: calc(#{$sessingGutter} * -1);
    $sessingGutterHalf: calc(#{$sessingGutter} * .5);

    padding: var(--session-gutter);
    border: 1px solid transparent;
    overflow: hidden;
    border-radius: var(--session-gutter);
    position: relative;

    @each $status in $statusList {
        &--#{$status} {
            border-color: map-get($theme-colors, $status);
        }
    }
    .ts-time-interval{
        font-weight: normal;
        background-color: transparent;
        img{
            width: 1.2em;
            height: 1.2em;
        }
    }
    .row{
        margin-left: $sessingGutterNeg;
        margin-right: $sessingGutterNeg;
        div[class*="col"] {
            padding-left: var(--session-gutter);
            padding-right: var(--session-gutter);
        }
    }
    .session-status {
        position: absolute;
        right: 0;
        top: 0;
        border-radius: 0 0 0 $sessingGutterHalf;
        padding: $sessingGutterHalf $sessingGutterHalf;
        z-index: 2;
        transition: transform .4s;
        transform: translate(calc(100% - 2.2em), 0px);
    }
    @include hover{
        .session-status{
            transform: translate(0px, 0px);
        }
    }
    @include media-breakpoint-down('lg'){
        --session-gutter: 10px;
        font-size: .9rem;
    }
}