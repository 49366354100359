html,
body,
div#root {
  height: 100%;
}

a,
a:hover {
  text-decoration: none !important;
}
.notification-icon button
{
  margin-right: 0;
  padding-right: 0;
}
.action-needed-popup .btn {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: .75rem;
}
// START: For header
.session-status.s-approve svg,.session-status.s-approve svg path {
  stroke: #222248;
}
.c-therapy-session-table .session-status.s-cancelled svg, .c-therapy-session-table .session-status.s-cancelled svg path {
  stroke: #fff;
}
.text-top.header-btn {
  display: inline-flex;
  flex-direction: column;
  align-items: end;
}
.lableTextArchive:hover
{
  color: #fff !important;
}
.c-therapy-session-table .session-status img, .c-therapy-session-table .session-status.s-approve svg path {
  stroke: #222248;
}
.c-therapy-session-table .session-status img, .c-therapy-session-table .session-status.s-approve svg
{
  stroke: #222248;
}
.card.border-none {
  border: 0;
}
.marketingAddRadio {
  display: flex;
  justify-content: space-between;
  width: 18%;
  margin-left: 20px;
}

.invoiceLogo {
  max-width: 250px;
  max-height: 100px;
  object-fit: contain;
}

.loader-overlay {
  position: absolute;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(255, 255, 255, 0.5);
  z-index: 1;
  width: 100%;
}

button.deleteBtn {
  background: transparent;
  border: 0;
  padding: 0;
  color: #387af6;
}

.modal-content .modal-header {
  padding: 10px 5px 5px;
}

.btn-close {
  box-sizing: content-box;
  padding: .25em;
  color: #000;
  background: transparent url('data:image/svg+xml;charset=utf-8,%3Csvg xmlns=http://www.w3.org/2000/svg viewBox=0 0 16 16 fill=%23000%3E%3Cpath d=M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z/%3E%3C/svg%3E');
  border: 0;
  border-radius: .375rem;
  opacity: .5;
}

.btn-close svg {
  width: 30px;
  height: 30px;
}

// .modal-header .btn-close {
//   //padding: calc(var(--bs-modal-header-padding-y)*0.5) calc(var(--bs-modal-header-padding-x)*0.5);
//   //margin: calc(var(--bs-modal-header-padding-y)*-0.5) calc(var(--bs-modal-header-padding-x)*-0.5) calc(var(--bs-modal-header-padding-y)*-0.5) auto;
// }
button.cardDelete {
  background: transparent;
  border: 0;
}

.addressInvoice {
  display: flex;
}

.Bankcard {
  border-radius: 10px;
  box-shadow: 0 0 6px rgba(0, 0, 0, .2);
  padding: 10px;
  margin-top: 20px;
}

.header {
  padding-top: 20px;
  padding-bottom: 20px;
}

.form-input-w-85per {
  width: 85%;
}

.notificationCheck .form-check-inline {
  margin-right: 5px;
}

.notificationCheck label {
  margin-right: 8px;
  display: inline-flex;
}

.slider {
  -webkit-appearance: none;
  width: 100%;
  height: 5px;
  background: #d3d3d3;
  outline: none;
  opacity: 0.7;
  -webkit-transition: 0.2s;
  transition: opacity 0.2s;
}

.mr-20 {
  margin-right: 20px !important;
}

.ml-20 {
  margin-left: 20px !important;
}

.ml-28 {
  margin-left: 28px !important;
}

.slider:hover {
  opacity: 1;
}

.disabled-dated-input {
  background: #e2e2e3;
}

.form-date-input {
  display: inline !important;
}

.form-date-input .rmdp-container .rmdp-input:focus {
  box-shadow: none;
}

.form-date-input .rmdp-container .rmdp-input {
  background-color: transparent !important;
  border: none !important;
  border-bottom: 1px solid !important;
  border-radius: 0 !important;
}

.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 15px;
  height: 15px;
  border-radius: 100%;
  background: #0565e2;
  cursor: pointer;
}

.mis-goal .rmdp-container {
  width: 100%;
}

.mis-goal .rmdp-container .rmdp-input {
  height: 48px;
  padding-left: 14px;
  border-radius: 12px;
}

.p-case-note-detail__actions .action-btn {
  width: 220px;
  height: 50px;
  border-radius: 5px;
  font-size: 1.375rem;
  margin-top: 20px;
}

.valign_wrapper_flex_mob {
  display: flex;
  -webkit-align-items: center;
  align-items: center;
}

.sessionFilterDate .rmdp-container .rmdp-input {
  margin-right: 10px;
}

.archiveGoalHeader {
  display: flex;
  padding: 10px 15px;
  justify-content: right;
  align-items: center;
}

.user-info {

  //margin-top: -13px;
  .admin-name {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .notification-icon {
    .badge {
      position: relative;
      top: -15px;
      left: -5px;
      /* border: 1px solid black; */
      border-radius: 50%;
      background-color: $color2;
      width: 23px;
      font-family: $base-font1;
      font-size: 0.75rem;
      font-weight: bold;
      line-height: 1.33;
      text-align: center;
      color: $white-color;
    }
  }

  .user-info-img {
    width: 60px;
    height: 60px;
    border-radius: 50%;
  }
}

// END : header

.custom-table-view {
  background: #fff;
  padding: 15px;
  border-radius: 15px;
  width: 100%;

  @include onMediumScreen {
    padding: 15px;
  }

  .content-header {
    display: flex;
    text-align: center;
    justify-content: space-between;
    padding: 15px;
    margin-left: -15px;
    margin-right: -15px;
    border-bottom: 1px solid $light-blue;
    padding-top: 0;

    .header-btn {
      align-self: center;
    }
  }
}

.recent-messages {
  display: flex;
  flex-direction: column-reverse;
}

.header-title {
  @include setMainHeading;
}

// START :: LOGIN CSS START
// .login-left {}
.left-img {
  max-height: 100%;
  height: 100vh;
  object-fit: cover;
  width: 100%;
  //object-position: top;
}

.login-left {
  width: 20%;
  flex: 0 0 27.83333%;
  max-width: 27.83333%;
}

.log-wrapper-left {
  position: absolute;
  top: 0;
  margin-left: 10%;
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 414px;
  width: 80%;

  @include onOnlyThisWidth(1024px) {
    margin-left: 5%;
  }

  @include onOnlyThisWidth(768px) {
    margin-left: 6%;
  }
}

.mask-login-left {
  width: 130%;
  height: auto;
}

.login-logo {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 20%;
  margin: auto;
  max-width: 70%;

  // @include onMobileScreen{
  //     margin-left: 25%;
  // }
  // @include onOnlyThisWidth(1024px){
  //     margin-left: 70%;
  // }
}

.custom-form {
  max-width: 500px;
  height: 100%;
  width: 100%;
}

.form-signin-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

// START :: Therapist List screen
.therapist {
  .user-info .admin-name {
    justify-content: flex-start;

    img {
      @include setThumbinail;
    }
  }

  .view-all-link {
    color: black;
    font-family: $base-font1;
    font-size: 0.9375rem;
    font-weight: 501;
    line-height: 0.63;
  }

  thead {
    background-color: $color4;

    th {
      background: #e8f1fe;
      font-family: $base-font1;
      font-size: 1rem;
      font-weight: 500;
      line-height: 2;
      text-align: left;
      color: #717171;

      @include onSmallScreen {
        font-size: 1rem;
      }

      @include onMobileScreen {
        font-size: 0.625rem;
      }
    }
  }
}

.therapist-detail {
  thead {
    background-color: $color4;

    th {
      background: #e8f1fe;
      padding: 2rem 0.75rem;
    }
  }
}

// END :: Therapist List screen
// START ::
.table-billing-info {
  tr td span {
    font-family: $base-font1;
    line-height: 2.1;
    color: $color1;
  }

  .table-nm {
    font-weight: 300;
    font-size: 1.25rem;
  }

  .table-bold-text {
    font-weight: 600;
    font-size: 1.25rem;
  }

  .dropdown-menu {
    .dropdown-item {
      font-family: $base-font1;
      font-size: 1.0625rem;
      line-height: 2.41;
      color: #3674e8;
      height: 20px;
      margin: 0px 0 0 21px;
      padding: 0px;
      text-align: center;
      align-items: center;
      display: flex;
      background: none;
      height: 30px;
    }
  }

  .dropleft {
    z-index: 999;

    .dropdown-toggle {
      border: none;
      background: none;
    }

    .button {
      border: none;
    }

    .dropdown-toggle::before,
    .dropdown-toggle:active,
    .dropdown-toggle:focus {
      border: none;
    }
  }
}

.p-10 {
  padding: 10px;
}

.ptb-20 {
  padding-top: 20px;
  padding-bottom: 20px;
}

.btn-theme-info {
  background-color: #f4f9ff;
  border-color: #aed0f7;
}

.recurring-sm-input {
  height: 30px;
  padding-top: 1px;
  border-radius: 4px;
  background-color: #e9ecef !important;
  // border: none;
}

.recurring-sm-input.form-control:focus {
  background-color: #e9ecef !important;
  border-color: transparent !important;
  outline: 0;
  box-shadow: none !important;
}

.mb-5px {
  margin-bottom: 5px !important;
}

.mt-12px {
  margin-top: 12px;
}

.mt-5px {
  margin-top: 5px;
}

.text-light-red {
  color: #d05a5a;
}

.recurring-sm-inpt {
  margin: 0;
  padding-top: 6px;
}

.recurring-count {
  margin: 0;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  padding-top: 6px;
}

.recurring-count-occur {
  border: none;
  margin: 0;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  padding-top: 6px;
}

.recurring-sm-input.form-control:focus {
  border-radius: 0 !important;
  border-bottom: 1px solid #387af6 !important;
}

.select-week-day {
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  width: 24px;
  height: 24px;
  font-size: 10px;
  font-weight: 500;
  border-radius: 50%;
  background-color: #f1f3f4;
  color: #80868b;
  margin-right: 8px;
  cursor: pointer;
}

.seleted-week-day {
  color: white;
  background-color: #1a73e8;
}

.rmdp-container .rmdp-input {
  background-color: #e9ecef !important;
  border: 1px solid #ced4da !important;
  width: 100% !important;
}

.input-date .rmdp-container {
  width: 100% !important;
}

.input-date .rmdp-container .rmdp-input {
  background: #f5f6fa !important;
  border: 1px solid #ced4da !important;
  padding: 0.375rem 0.75rem !important;
  font-weight: 400 !important;
  display: block !important;
  height: 50px !important;
}

.sm-date-picker .rmdp-container .rmdp-input {
  height: 30px !important;
}

.inline-flex {
  display: inline-flex !important;
}

.conetent-title {
  font-size: 1.2rem;
}

.font-size-1-55-rem {
  font-size: 1.55rem !important;
}

.font-size-16 {
  font-size: 16px !important;
}

.font-size-12 {
  font-size: 12px;
}

.font-bold-500 {
  font-weight: 500 !important;
}

// Add Therpist CSS
.addtheripist {
  margin-top: 0px;
  width: 100%;
  max-width: 1010px;
  padding: 20px;

  .conetent-title {
    @include setMainHeading;
  }

  .fileuplaod {
    padding: 10px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 16px;
    height: 259px;
    border: solid 2px $light-blue;
    background-color: $body-bg;

    input[type="file"] {
      opacity: 0;
      position: Absolute;
    }

    img {
      height: 170px;
    }
  }

  .content-box {
    text-align: center;
  }

  .file-title {
    margin-top: 10px;
    color: $color1;
    font-family: $base-font1;
    font-size: 1.375rem;
  }

  .file-name {
    font-size: 0.75rem;
    margin-top: 15px;
  }
}

.date-radio {
  position: relative;
  padding: 11px;
  width: 65px;
  height: 70px;
  border-radius: 6px;
  /* background-color: $color2; */
  color: $color2  !important;
  font-size: 1.125rem !important;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin: 10px;
  flex: 1;
  padding: 0;

  input[type="radio"] {
    position: absolute;
    opacity: 0;
    width: 100%;
    height: 70px;
    cursor: pointer;

    &:checked {
      &+.days {
        width: 100%;
        height: 70px;
        border-radius: 6px;
        background-color: $color2;
        margin: -12px;
        color: white;
        padding: 12px;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
      }
    }
  }
}

.working-days-checkbox {
  position: relative;
  padding: 0px;
  // width: 65px;
  height: 46px;
  border-radius: 6px;
  /* background-color: $color2; */
  color: $color2  !important;
  font-size: 1.125rem !important;
  // display: flex;
  justify-content: center;
  align-items: center;

  // margin: 10px;
  .days {
    height: 46px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  input[type="checkbox"] {
    position: absolute;
    opacity: 0;
    width: 96px;
    height: 46px;
    cursor: pointer;

    &:checked {
      &+.days {
        // width: 65px;
        height: 46px;
        border-radius: 6px;
        background-color: $color2;
        // margin: -12px;
        color: white;
        padding: 12px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
}

.custom-table .lang {
  font-family: $base-font1;
  font-size: 1.125rem;
  font-weight: 500;
  line-height: 1.33;
  color: $color1;
}

.selectd-date-text {
  font-family: $base-font1;
  font-size: 1.25rem;
  line-height: 1.3;
  color: $color1;
  font-weight: normal;

  @include onSmallScreen {
    font-size: 1rem;
  }
}

// }
// .form-signin-wrapper form .input-data label{
//     position: absolute;
//     font-weight: normal;
//     font-size: medium;
//     left:380px;
//     margin-top: -40px;
//     bottom: auto;
//     color: #999;
.input-data input:focus~label {
  transform: translateY(-20px);
  color: #999;
  font-size: small;
}

.NoPath---Copy-46 {
  width: 196px;
  height: 191px;
  margin: 35px 48px 64px 3px;
  object-fit: contain;
  border-radius: 317px;
}

//Settings
.green-submit-button {
  width: 175px;
  height: 43px;
  padding: 0;
  border-radius: 8px;
  background-image: linear-gradient(to bottom, #0fd683, #10c177);
  font-family: "Roboto", sans-serif;
  font-size: 1.4rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.31;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
}

//details
.name {
  font-weight: 500;
}

.casesnotes {
  width: 1364px;
  height: 160px;
  margin: 40.5px 57px 45px 18px;
  padding: 28px 38px 38px 34px;
  background-color: $bg-color2;
  border-radius: 10px;
}

.detailsvideo {
  margin-left: 62%;
}

// }
// .input-data input:focus ~ label{
//     transform: translateY(-20px);
//     color: #999;
//     font-size: small;
// }
//TherapistDashboard
.seperator {
  border-left: 2px solid $white-color;
  height: 60px;
  opacity: 0.2;
}

.seperator1 {
  border-left: 2px solid $bg-color2;
  height: auto;
}

.font-content {
  color: $white-color;
  font-size: 1.0625rem;
}

.font-content1 {
  color: $white-color;
  font-size: 1.875rem;
  font-weight: 700;
}

.font-content2 {
  color: $white-color;
  font-size: 0.9375rem;
  font-weight: 501;
}

.font-content3 {
  color: $white-color;
  font-size: 0.8125rem;
  font-weight: 500;
}

.Mon-14 {
  font-size: 1.625rem;

  .text-style-1 {
    font-weight: bold;
    font-size: 1.4375rem;
  }

  button {
    font-size: 1.375rem;
    background-color: $white-color;
    height: 69px;
    border: 0px;
  }
}

.Mon-14 button:hover {
  color: $white-color;
  border-radius: 7px;
  border: solid 1px $color2;
  background-color: $color2;
}

.Mon-14 .btn:hover {
  color: $white-color;
  background-color: $color2;
}

.Rectangle-691 {
  /*arrow box*/
  width: 40px;
  height: 90px;
  border-radius: 3px;
  background-color: $bg-color2;
  padding: 0px 0px 0px 0px;
}

//End for Now
//Interpreter Detail
.seperator2 {
  border-left: 2px solid $color5;
  height: auto;
  padding-left: 20px;
}

.lang-box {
  padding: 12px 10px 14px;
  border-radius: 8px;
  background-color: $light-blue;
  font-family: $base-font1;
  font-size: 1rem;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  text-align: center;
  color: $color1;
}

//TherapistVideoTherapy
.Path-103 {
  width: 495px;
  height: 928px;
  margin: 23px 0 0 49px;
  padding: 34px 35px 33px 33px;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.1);
  background-color: $white-color;
  border-radius: 16px;
}

.Rectangle-876 {
  width: 425px;
  height: 618px;
  margin: 30px 2px 36px 0;
  padding: 25px 24px 291px;
  object-fit: contain;
  border-radius: 16px;
  border: solid 1px #bdcbd6;
  background-color: $white-color;
}

.Dr-John-L-Glenn {
  width: 89px;
  height: 16px;
  margin: 0 288px 10px 0;
  font-family: $base-font1;
  font-size: 0.75rem;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 3.25;
  letter-spacing: normal;
  text-align: left;
  color: #373737;
}

.p {
  width: 377px;
  height: 276px;
  margin: 10px 0 0;
  font-family: $base-font1;
  font-size: 0.9375rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: 0.3px;
  text-align: left;
  color: #373737;
}

.Path-100 {
  border-radius: 5px;
  margin: 0 23px 23px 0;
  padding: 12px 23.9px 10px 23.6px;
  background-color: $light-blue;
}

.videoimg {
  width: 120%;
  height: 100%;
}

.custom-table-view2 {
  background: #fff;
  padding: 15px;
  border-radius: 15px;
  width: 85%;
  margin-left: 10%;
}

//end

//theropysession

.search-container .btn svg {
  width: 25px;
  height: auto;
}

.search-container .btn {
  height: 41px;
  margin-top: 2px;
  padding: 8px;
}

.theropySession .form-control {
  font-size: 1rem !important;
  height: 45px !important;
}

.therapyListWrapper .input-group-prepend {
  width: 44px;
  background-color: #387af6;
  border-top-right-radius: 12px;
  border-bottom-right-radius: 12px;
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
}

.therapyListWrapper {
  position: relative;
}

.theropySession textarea.form-control {
  height: 70% !important;
}

.theropySession .form-group {
  margin-bottom: 1rem;
}

.fieldError {
  color: red;
}

.activeDropDown .toggleArrow {
  transform: rotate(0deg);
  fill: #fff !important;
}

.toggleArrow {
  float: right;
  transform: rotate(180deg);
}

.sidebar-item a.childMenuHeading {
  display: block;
  text-align: left;
}

.dropDownMenuList li {
  padding-left: 12px;
}

.activeDropDownMenu {
  background-color: #dfe8f5;
}

.textDisable {
  color: #6c757d !important;
  opacity: 0.7;
}

.custom-table-view .content-header .nav-tabs {
  border-bottom: 0;
}

.disable-week-days.seleted-week-day {
  opacity: 0.5;
  cursor: default !important;
}

.disable-week-days.seleted-week-day:hover {
  color: #fff !important;
}

.disableInput {
  opacity: 0.6 !important;
}

//for img
.image {
  @include setThumbinail;
}

.c-thumbnail {
  width: 150px;
  max-width: 100%;
  position: relative;
  border-radius: 50%;
  margin-top: 15px;
  margin-left: 10px;
  overflow: hidden;

  &:after {
    content: "";
    display: block;
    padding-top: 100%;
  }

  &__image {
    object-fit: cover;
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
  }

  @include onMobileScreen {
    width: 100px;
    height: 100px;
    margin-top: 35px;
  }
}

.settingsimg {
  width: 150px;
  height: 150px;
  margin: 5px 48px 35px 3px;
  border-radius: 317px;
  object-fit: cover;

  @include onMobileScreen {
    width: 100px;
    height: 100px;
    margin: 20px 40px 64px 0px;
  }

  @include onOnlyThisWidth(1024px) {
    margin: 20px 40px 64px 0px;
  }
}

.setting-img-container {
  width: 150px;
  height: 150px;
  margin: 35px 48px 35px 3px;
}

.setting-img-container img {
  width: 100%;
}

.settingsChildimg {
  width: 100px;
  height: 100px;
  margin-bottom: 35px;
  border-radius: 317px;
  object-fit: cover;
}

.card-1 {
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  padding: 10px 5px;
  border-radius: 8px;
}

.card-1:hover {
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
}

.border-bottom {
  border-bottom: 1px solid #d8e4f1;
}

.nodatafound {
  margin-left: 30%;
}

.nodatafoundtable {
  margin-left: 70%;
}

.choosefile {
  opacity: 0;
  margin-top: -30%;
  margin-left: -40%;
}

.change {
  margin-top: -55%;
  font-size: 1.125rem;
  margin-left: -10%;
}

.change1 {
  margin-top: -140%;
  font-size: 1.125rem;
  margin-left: -10%;
}

.add-your-ques {
  width: 300px;
  height: 50px;
  border-radius: 8px;
  background-image: linear-gradient(to bottom, #22dd8f, #11ab6b);
  padding-left: 55px;
  font-family: $base-font1;
  font-size: 1.4375rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.31;
  letter-spacing: normal;
  text-align: left;
  color: $white-color;
  margin-left: 10%;
}

.styled-checkbox {
  position: absolute; // take it out of document flow
  opacity: 0; // hide it

  &+label {
    position: relative;
    cursor: pointer;
    padding: 0;
  }

  // Box.
  &+label:before {
    content: "";
    margin-right: 10px;
    display: inline-block;
    vertical-align: middle;
    width: 25px;
    height: 25px;
    border: gray 1px solid;
    //   background: white;
  }

  // Box hover
  // &:hover + label:before {
  //   background: $color2;
  // }

  // Box focus
  // &:focus + label:before {
  //   box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.12);
  // }

  // Box checked
  &:checked+label:before {
    background: $color2;
  }

  // Disabled state label.
  &:disabled+label {
    color: #b8b8b8;
    cursor: auto;
  }

  // Disabled box.
  &:disabled+label:before {
    box-shadow: none;
    background: #ddd;
  }

  // Checkmark. Could be replaced with an image
  &:checked+label:after {
    content: "";
    position: absolute;
    left: 7px;
    top: 12px;
    background: white;
    width: 2px;
    height: 2px;
    box-shadow: 2px 0 0 white, 4px 0 0 white, 4px -2px 0 white, 4px -4px 0 white,
      4px -6px 0 white, 4px -8px 0 white, 4px -10px 0 white;
    transform: rotate(45deg);
  }
}

.dob-box {
  @include onMobileScreen {
    width: 80px;
  }

  @include onOnlyThisWidth(1024px) {
    width: 80px;
  }
}

.dash-blue {
  @include onMobileScreen {
    margin-right: 25%;
  }
}

.dash-blue-div {
  @include onMobileScreen {
    margin-left: 20%;
  }

  @include onOnlyThisWidth(1024px) {
    margin-left: 8%;
  }
}

.tenant-logo-img {
  max-width: 40%;
  margin-top: 2%;
}

.tenant-logo-img-header {
  width: 190px;
}

.login-page {
  background-image: linear-gradient(to bottom, #24d5ff, #16c67d);
}

.custom-table-view-login {
  background: #eaf5ff;
  padding: 60px 50px 80px 50px;
  border-radius: 15px;
  width: 90%;
  margin: auto;
  //opacity: 0.9;
}

.rbc-overlay {
  z-index: 9999 !important;
  min-height: 100px;
  max-height: 225px;
  overflow: auto;
}

.fileuplaod {
  #fileuplaod {
    width: 100%;
    height: 100%;
  }
}

.dashboard-session-cal {
  min-width: 678px;

  .back {
    padding-left: 15px;
    padding-right: 15px;

    h6 {
      font-weight: normal;
    }
  }
}

::-webkit-calendar-picker-indicator {
  background: none;
  display: none;
}

.soap-note-tabs {
  .nav-link.active {
    background-color: #cbcbcb !important;
    color: black !important;
    border: 0px;
    border-bottom: 3px solid $color2  !important;
    font-weight: bold;
    font-family: $base-font1;
  }

  .nav-link {
    font-size: 20px;
    border-radius: 3px 0 0 0 !important;
  }
}

.custom-nav-tabs {
  .nav-tabs {
    border: 0px;
    background: $bg-color2;

    .nav-link.active {
      background-color: $bg-color2;
      color: black;
      border: 0px;
      border-bottom: 3px solid $color2;
      font-weight: bold;
      font-family: $base-font1;
    }

    a {
      color: $color1;
      font-size: 18px;
    }

    .nav-link {
      border-radius: 0px;
    }
  }

  .tab-content {
    border-left: solid 1px $color8;
    border-right: solid 1px $color8;
    border-bottom: solid 1px $color8;
  }
}