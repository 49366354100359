.p-case-notes {
  padding-bottom: 30px;
  &__header {
    .btn {
      svg {
        width: 25px;
        height: auto;
      }
    }
    .form-control {
      height: 46px;
      margin: 0 16px 0 0;
      padding: 14px 13px;
      border-radius: 4px;
      border: solid 1px $color3;
      background-color: $white-color;
    }
  }
  &__main-box {
    display: flex;
  }
  &__table {
    width: 946px;
    &--full {
      width: 100%;
    }
    &-header,
    &-row {
      display: flex;
      align-items: center;
      & > div {
        flex: 1;
        line-height: 1.3;
        @include onMediumScreen {
          &:first-child {
            flex: 1 1 120px;
          }
        }
      }
      & > div:last-child {
        flex: 170px 0;
      }
    }
    &-row {
      // min-height: 96px;
      border-bottom: solid 1px $light-blue;
      @include hover {
        background: $bg-color1;
      }
      &--active {
        background: $bg-color1;
      }
    }
    &-header {
      background: $color4;
      height: 66px;
      @include onLargeScreen {
        height: auto;
      }
    }
    &-head-col,
    &-col {
      padding: 20px;
      font-size: 1.125rem;
      line-height: 0.56;
      @include onMediumScreen {
        font-size: 0.875rem;
        padding: 10px 15px;
        .btn {
          font-size: 0.875rem;
        }
      }
    }
    &-col {
      @include onMediumScreen {
        // font-size: 0.75rem;
        .btn {
          // font-size: 0.75rem;
        }
      }
    }
  }
  &__child {
    &-info {
      display: flex;
      align-items: center;
      span {
        font-weight: 500;
        color: $color5;
        display: inline-block;
      }
      &-img {
        @include setThumbinail;
        margin-right: 10px;
      }
    }
  }
  &__col-detail {
    flex: 1;
    display: flex;
    flex-direction: column;
    &:before {
      height: 66px;
      display: block;
      content: "";
      background-color: $color4;
    }
    &-inner {
      flex: 1;
      padding: 46px 50px 23px;
      background-color: $bg-color1;
    }
  }
  &__card {
    padding: 33px 0;
    color: $color6;
    border-bottom: solid 1px $light-blue;
    a {
      font-size: 1rem;
      font-weight: 500;
    }
    &-info {
      padding: 30px 0;
      font-size: 1.125rem;
      display: flex;
      justify-content: space-between;
    }
    &-title {
      @include setHeading;
    }
    &-content {
      font-size: 1.125rem;
      font-weight: 300;
      line-height: 1.44;
    }
  }
}

.p-case-note-detail {
  border-radius: 16px;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.1);
  background-color: $white-color;
  .form-control {
    height: 46px;
    border-radius: 5px;
  }
  .select-wrapper.disable-select {
    &::before {
      content: "";
      display: block;
      position: absolute;
      right: 0;
      top: 0;
      bottom: 0;
      width: 50px;
      pointer-events: none;
      // background-color: $color2;
      background-color: #b6cefc;
    }
  }
  .select-wrapper {
    display: inline-block;
    position: relative;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    overflow: hidden;
    &::before {
      content: "";
      display: block;
      position: absolute;
      right: 0;
      top: 0;
      bottom: 0;
      width: 50px;
      pointer-events: none;
      background-color: $color2;
    }
    &::after {
      content: "";
      margin: auto;
      display: block;
      position: absolute;
      right: 20px;
      top: 0;
      bottom: 8px;
      width: 10px;
      height: 10px;
      pointer-events: none;
      transform: rotate(225deg);
      border-top: 1px solid $white-color;
      border-left: 1px solid $white-color;
    }
    select {
      padding-right: 55px;
    }
  }
  .paperclip-btn {
    margin-top: 10px;
    margin-bottom: 10px !important;
  }
  &__child {
    &-name {
      @include setMainHeading;
      margin-bottom: 0;
    }
  }
  &__header {
    border-bottom: solid 1px rgba($color2, 0.2);
    padding: 30px 20px 20px 40px;
    @include onMediumScreen {
      padding: 15px 20px 10px 40px;
    }
  }
  &__body {
    // border-bottom: solid 1px rgba($color2, 0.2);
    padding: 20px 40px 20px 102px;
    margin-bottom: 40px;
  }
  &__user {
    display: flex;
    align-items: center;
    margin-left: -62px;
    padding-bottom: 20px;
    img {
      @extend .p-case-notes__child-info-img;
    }
  }
  &__info {
    padding-top: 20px;
    margin-bottom: 20px;
    border-top: solid 1px rgba($color2, 0.2);
    border-bottom: solid 1px rgba($color2, 0.2);
    display: flex;
    flex-wrap: wrap;
    &-col {
      min-width: 180px;
      margin-right: 90px;
      &--fixed-width {
        width: 180px;
      }
      @include onMediumScreen {
        min-width: 150px;
        &--fixed-width {
          width: 180px;
        }
      }
    }
    &-label {
      margin-bottom: 5px;
      font-weight: bold;
    }
    &-value {
    }
  }
  &__date {
    font-size: 1.25rem;
    font-weight: bold;
    line-height: 1.23;
    color: $color1;
    padding-top: 15px;
    padding-bottom: 20px;
  }
  &__content {
    &-heading {
      @extend .p-case-note-detail__date;
      padding-bottom: 0;
    }
    .action-btn {
      width: 220px;
      height: 50px;
      border-radius: 5px;
      font-size: 1.375rem;
      margin-top: 20px;
      &:not(:last-child) {
        margin-right: 46px;
      }
    }
  }
  &__input {
    width: 100%;
    height: 216px;
    padding: 26px 26.8px 28.5px 28px;
    border-radius: 10px;
    border: solid 1px $color7;
    background-color: $white-color;
    overflow: auto;
    resize: none;
    @include scrollbar;
    font-size: 1.125rem;
    font-weight: 300;
    line-height: 1.44;
    color: $color5;
    &:focus {
      outline: none;
    }
    &--translate {
      border: none;
      box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.16);
    }
  }
  &__main-casenote {
    &--submitted {
      @extend .c-query__main;
      .p-case-note-detail {
        &__content-heading {
          padding-top: 0;
        }
      }
    }
  }
  &__share {
    cursor: pointer;
    font-size: 1rem;
    font-weight: 500;
    color: $color2;
  }
}

.c-casenote-billing-info {
  &__image {
    max-width: 300px;
    max-height: 100px;
    object-fit: contain;
    @include onMediumScreen {
      max-width: 250px;
    }
  }
}

.sign-img-new {
  width: 150px !important;
  height: 50px !important;
  object-fit: contain;
  border: 1px solid rgba(0, 0, 0, 0.16);
  border-radius: 5px;
}
.sign-img {
  width: 150px;
  height: 50px;
  object-fit: scale-down;
  border: 1px solid rgba(0, 0, 0, 0.16);
  border-radius: 5px;
}
.btn.has-loader {
  position: relative;
  padding-right: 40px;
  .loader-small {
    position: absolute;
    right: -10px;
    top: -7px !important;
    bottom: auto !important;
  }
}

.error-text {
  @extend .text-danger;
  font-size: 0.75rem;
}

.sigCanvas {
  // border: 2px solid;
  height: 60px;
  width: 300px;
}

.c-casenote-print {
  width: 950px;
  height: 1343.5px;
  .p-case-note-detail {
    background: linear-gradient(0, #000000 1px, white 2px, white 1342px);
    background-position: top left;
    background-size: 10px 1343.5px;
    /* background-repeat-x: no-repeat; */
  }

  .p-case-note-detail {
    width: 950px;
    min-width: 950px;
    max-width: 950px;
  }

  .p-case-note-detail {
    font-size: 14px;
  }

  .p-case-note-detail .header-title,
  .p-case-note-detail h4 {
    font-size: 20px;
  }
}
