$base-font1: "Roboto", sans-serif;
$base-font2: "Poppins", sans-serif;

// sizes
$radius1: 3px;
$radius2: 5px;
$radius3: 8px;
$radius4: 12px;
$radius5: 16px;
$radius6: 40px;
$radius7: 52px;

// $grid-gutter-width: var(--gutter-width, 15px);

// color variable
$body-bg: #f5f6fa;
$btn-green: #0fd683;
$btn-yellow: #ffd629;

$bg-color1: #f7f9fd;
$bg-color2: #eaf1fe;
$bg-color3: #e0e6f2;
$white-color: #ffffff;
$black-color: #000000;
$color1: #222248;
$color2: #387af6; // sidebar active color
$color3: #7f9aaf;
$color4: #e8f1ff;
$color5: #414141;
$color6: #191919;
$color7: #b7b7b7;
$color8: #97b7de;
$light-blue-2: #e1eafc;
$light-blue: #d8e4f1;
$light-cyan: #e5ffff; //#ccffff;

// status colors
$s-complete: $color2;
$s-completePay: #0fd683;
$s-approve: #e2f6e9;
$s-pending: #ffcecc;
$s-missed: #f5f59b;
$s-cancelled: #d93025;
$s-warning: #fbb64f;
$s-hide:#808080;

$statusList: "complete", "approve", "pending", "missed", "cancelled";

$theme-colors: (
  "danger": #fc341d,
  "theme-blue": $color2,
  "theme-light-blue": $light-blue,
  "theme-light-red": #ffcecc,
  "complete": $color2,
  "approve": #e2f6e9,
  "pending": #ffcecc,
  "missed": #f5f59b,
  "cancelled": #d93025,
  "light-cyan": $light-cyan,
  "warning": #fbb64f,
);
@import "~bootstrap/scss/bootstrap.scss";
@import "./resources/css/style.scss"; // common css style

@import "./resources/css/custom.scss";
@import "./resources/css/utilities.css";
@import "./resources/css/animations.css";
body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: $body-bg;
  font-family: $base-font1;
}
.container {
  max-width: inherit;
}

body,
:root,
html {
  font-size: 16px;
  @include onMediumScreen {
    font-size: 14px;
  }
}

.white-board-container {
  border: 2px solid;
}

.dropdown-toggle.custom-dropdown-toggle::after {
  display: none !important;
}

body > iframe {
  display: none;
}

.action-needed-popup {
  // width: 22em !important;
  height: 150px;
}

.cardBox {
  background-color: #fff;
  padding: 15px;
}

.tenant_info .row {
  margin-bottom: 20px;
}
