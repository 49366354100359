.c-app-loader{
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    z-index: 99999999;
    background-color: rgba($white-color, .3);
    opacity: 0;
    pointer-events: none;
    transition: opacity .2s;
    &--show{
        opacity: 1;
        pointer-events: all;
    }
}