.msg{
    &-complete-box{
        position: relative;
        text-align-last: left;
        background-color: $bg-color1;
        padding-bottom: 180px !important;
        .session-detail-date {
            // position: sticky;
            // top: 0px;
            margin-bottom: 0;
            background: $bg-color1;
            margin-top: 0 !important;
            padding-top: 10px;
            padding-bottom: 10px;
        }
        &-inner{
            width: 100%;
            height: 320px;
            overflow: auto;
            @include scrollbar;
        }
        form {
            background: $bg-color1;
            height: 180px;
            position: absolute;
            bottom: 0;
            left: 0;
            right: 30px;
            padding: 20px;
            margin: auto;
            z-index: 3;
        }
        textarea{
            resize: none;
        }        
    }
    &-user{
        &-name {
            display: flex;
            font-weight: 500;
            width: 100%;
        }
        &-time {
            font-weight: 300;
            padding-left: 5px;
        }
        &-img {
            width: 40px;
            margin-right: 10px;
            img {
                width: 100%;
                height: 40px;
                object-fit: cover;
                border-radius: 50%;
            }   
        }
        
    }
    &-text {
        font-weight: 300;
        padding: 10px 15px;
        background: $light-blue-2;
        border-radius: 0px 10px;
        width: calc(100% - 100px);
    }
    &-item {
        display: flex;
        flex-wrap: wrap;
        padding: 10px 0;
        &--nosender{
            .msg{
                &-user{
                    &-name,
                    &-img img{

                        display: none;
                    }
                }
            }
        }
        &--local{
            flex-direction: row-reverse;
            .msg{
                &-user{
                    &-name{
                        justify-content: flex-end;
                    }
                    &-img {
                        margin-left: 10px;
                        margin-right: 0px;
                        // display: none;
                    }
                }
            }
        }
    }
    &-list{
        width: calc(100% - 20px);
    }
}
.c-recent-msg{
    &__img{
        @include setThumbinail;
        margin-right: 10px;
    }
}