.c-basic-user-detail{
    &__status{
        &-row{
            padding-top: 15px;
        }
        padding-left: 10px;
        padding-right: 10px;
        max-width: 300px;
        &:first-child{
            padding-left: 0;
        }
        &:not(:first-child){
            border-left: 1px solid $color7;
        }
    }
    &__image-col{
        width: 200px;
        padding-left: 15px;
        padding-right: 15px;
    }
    &__content-col{
        width: calc(100% - 200px);
        padding-left: 15px;
        padding-right: 15px;
    }
    &__edit-col{
        width: 100px;
        padding-left: 15px;
        padding-right: 15px;
    }
}