.c-emoji{
    display: flex;
    height: 100vh;
    &__holder{
        pointer-events: none;
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        top: 0;
        z-index: 99;
    }
    &__image{
        &-wrapper{
            transform: scale(0);
            opacity: 0;
            display: flex;
            flex-direction: column;
            align-items: center;
            &[wobble="1"]{
                animation: jumpUp forwards 3.4s;
            }
        }
    }
    &__sender{
        position: relative;
        &-name{
            background-color: rgba(#007bff, .95);
            padding: 5px 8px;
            border-radius: 5px;
            color: #fff;
            font-size: 14px;
            letter-spacing: .1px;
        }
        &-list {
            position: relative;
            display: flex;
            flex-wrap: wrap;
            padding: 20px;
            overflow: auto;
            height: 400px;
            width: 460px;
            &-wrapper{
                overflow: hidden;
                border-radius: 15px;
                background: #fff;
                position: absolute;
                bottom: 100%;
                right: 0;
                z-index: 101;
                &:after,&x:before{
                    content: "";
                    display: block;
                    position: absolute;
                    left: 0;
                    right: 0;
                    height: 40px;
                    pointer-events: none;
                    z-index: 9;
                }
                &:after{
                    bottom: 0;
                    background: linear-gradient(0deg, #fff, transparent);
                }
                &:before{
                    top: 0;
                    background: linear-gradient(180deg, #fff, transparent);
                }
            }
        }
        &-btn{
            padding-left: 5px;
            padding-right: 5px;
            &--more{
                display: inline-flex;
                justify-content: center;
                align-items: center;
                span:after, &:after, &:before{
                    content: "";
                    display: block;
                    width: 7px;
                    height: 7px;
                    background-color: red;
                    margin: 3px;
                    border-radius: 50%;
                }
            }
        }
    }
}
@keyframes jumpUp {
    0% {
        transform: scale(0);
        opacity: 1;
    }
    10% {
        transform: scale(1);
        opacity: 1;
    }
    80% {
        transform: scale(1);
        opacity: 1;
    }
    100% {
        transform: scale(1);
        opacity: 0;
    }
}